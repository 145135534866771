import React from 'react';
import ServicePageContent from "../servicePageContent/servicePageContent";

import './responsive-table.scss';

import contents from "./privacyPageContent.yml";

const PrivacyPageContent = () => {

  const {
    title,
    body
  } = contents;

  return (
    <ServicePageContent
      title={title}
    >{body}</ServicePageContent>
  )
};

export default PrivacyPageContent;
