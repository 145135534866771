import React from "react"
import SEO from "../components/seo"
import PrivacyPageContent from "../components/privacyPageContent";



const PrivacyPage = () => (
  <main>
    <SEO
      title="Privacy"
      description="Qui trovi la privacy policy del sito ceres.com"
    />
    <PrivacyPageContent />
  </main>
);

export default PrivacyPage;
